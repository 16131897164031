import * as React from "react";

import Layout from "../components/layout/Layout";
import Seo from "../components/seo";
import ContentColumn from "../components/ui/ContentColumn";

const ConsigliDiPulito = () => {
  return (
    <>
      <Seo
        title="Consigli di pulito"
        description="Lorem ipsum dolor sit amet"
      />
      <Layout>
        <ContentColumn>
          <h1>Consigli di pulito</h1>
        </ContentColumn>
      </Layout>
    </>
  );
};

export default ConsigliDiPulito;
